/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import phoneInput from '@/views/components/input/phoneInput.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import './overwrite-quill.css'

export default {
  components: {
    phoneInput,
    quillEditor,
  },
  data() {
    return {
      isPickup: null,
      customTemplateEditCOD: '',
      customTemplateEditPickup: '',
      customTemplateShow: '',
      customTemplateSend: '',
      komshipCODTemplate: '',
      komshipPickupTemplate: '',
      disabledEdit: false,
      contenteditable: null,

      customerPhone: '',
      isWhatsapp: null,

      validateSendDemo: true,
      errorCharPassword: false,

      isEditMode: true,
      previewMessage: '',
      content: '',
      quill: null,
      characterCount: 0,
      editorOption: {
        theme: 'snow',
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'strike'],
          ],
        },
      },
    }
  },
  mounted() {
    if (!this.connected_wa) {
      this.$router.push({ name: 'dashboard-whatsapp' })
    }
    if (this.$route.path === '/opsional-feature/template-notification-pickup') {
      this.isPickup = true
    } else {
      this.isPickup = false
    }
    this.getDefaultMessage()
  },
  computed: {
    isPickupMode() {
      return this.$route.path === '/opsional-feature/template-notification-pickup'
    },
    toolButtonVariant() {
      return this.isEditMode ? 'outline-primary' : 'outline-secondary'
    },
    templateMessage() {
      return this.isPickupMode ? this.komshipPickupTemplate : this.komshipCODTemplate
    },
    previewChatbox() {
      return this.isEditMode ? this.content : this.templateMessage
    },
    isDisableTerapkanButton() {
      if (this.isEditMode) {
        return this.characterCount > 1000 || this.characterCount < 10
      }
      return false
    },
    isDisableSendButton() {
      return this.isDisableTerapkanButton || this.isWhatsapp === null || this.isWhatsapp === 'invalid'
    },
    ...mapState('whatsapp', ['connected_wa', 'list_message']),
  },
  watch: {
    content() {
      this.characterCount = this.quill.container.textContent.length
    },
  },
  methods: {
    getDefaultMessage() {
      this.komshipCODTemplate = this.list_message.default_message.message_arrived_at
      this.komshipPickupTemplate = this.list_message.default_message.message_pickup
      this.customTemplateEditCOD = this.list_message.list_messages[1].message === null ? '' : this.formatBeforePreview(this.list_message.list_messages[1].message)
      this.customTemplateEditPickup = this.list_message.list_messages[0].message === null ? '' : this.formatBeforePreview(this.list_message.list_messages[0].message)
      this.content = this.isPickupMode ? this.customTemplateEditPickup : this.customTemplateEditCOD

      if (this.$route.name === 'template-notification-pickup') {
        this.isEditMode = this.list_message.list_messages[0].is_custom === 1
        this.previewMessage = this.isEditMode ? this.customTemplateEditPickup : this.komshipPickupTemplate
      } else {
        this.isEditMode = this.list_message.list_messages[1].is_custom === 1
        this.previewMessage = this.isEditMode ? this.customTemplateEditCOD : this.komshipCODTemplate
      }

      if (this.isEditMode) {
        this.disabledEdit = true
        this.activeBold = true
        this.activeItalic = true
        this.activeStrike = true
        this.contenteditable = false
      } else {
        this.contenteditable = true
      }
    },
    addVariable(value) {
      const editorEl = this.$refs['whatsapp-editor'].$el

      // Get the current selection and range
      const selection = window.getSelection()
      const range = selection.getRangeAt(0)

      const caretIsOutsideEditor = !editorEl.contains(range.commonAncestorContainer)

      if (caretIsOutsideEditor) {
        return
      }

      // Create a text node with the desired text
      const textNode = document.createTextNode(value)

      // Insert the text node at the cursor position
      range.insertNode(textNode)

      // Move the cursor to the end of the inserted text
      range.setStartAfter(textNode)
      range.setEndAfter(textNode)

      // Clear the current selection and set the new range
      selection.removeAllRanges()
      selection.addRange(range)

      // Set focus back to the contenteditable element
      editorEl.focus()
    },
    formatBeforePreview(htmlString) {
      return htmlString.replace(/<strike>/g, '<s>').replace(/<\/strike>/g, '</s>')
    },
    sendDemo() {
      let templateSend = ''
      const numberPhone = `${this.customerPhone}`
      if (this.isEditMode) {
        templateSend = this.generateHTMLContent()
      } else {
        templateSend = this.$refs.templatemessage.innerHTML
      }
      const body = {
        message: templateSend,
        phone_number: parseInt(numberPhone, 10),
      }

      if (this.validateSendDemo && this.customerPhone > 9) {
        this.validateSendDemo = false
        this.$http_komship.post('/v2/wap/test-notification', body)
          .then(res => {
            if (res.data.code === 200) {
              this.validateSendDemo = true
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sukses',
                  icon: 'CheckIcon',
                  text: 'Notifikasi Whatsapp berhasil dikirim',
                  variant: 'success',
                },
              }, 2000)
            }
          })
          .catch(() => {
            this.validateSendDemo = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: 'Gagal mengirim notifikasi whatsapp',
                variant: 'danger',
              },
            }, 2000)
          })
      }
    },
    modalConfirmTemplate() {
      this.$swal({
        title: 'Terapkan Notifikasi',
        text: 'Apakah kamu yakin mau menerapkan notifikasi ini ?',
        imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
        confirmButtonText: 'Aktifkan',
        confirmButtonClass: 'btn btn-primary',
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'Batal',
        cancelButtonColor: '#FFFFFF',
        cancelButtonClass: 'btn btn-outline-primary text-primary',
      }).then(response => {
        if (response.isConfirmed) {
          this.activateTemplate()
        }
      })
    },
    activateTemplate() {
      let messageId = 0
      let toastSuccess = ''
      let toastFailed = ''
      const templateContent = this.generateHTMLContent()
      const body = {}

      if (this.isEditMode) {
        body.is_custom = 1
        body.message = templateContent
        toastSuccess = 'Custom Template Notifikasi Whatsapp berhasil diterapkan'
        toastFailed = 'Gagal menyimpan custom template notifikasi whatsapp'
      } else {
        body.is_custom = 0
        toastSuccess = 'Template Notifikasi Whatsapp berhasil diterapkan'
        toastFailed = 'Gagal menyimpan template notifikasi whatsapp'
      }

      if (this.isPickup) {
        messageId = this.list_message.list_messages[0].id
      } else {
        messageId = this.list_message.list_messages[1].id
      }

      this.$http_komship.put(`/v2/wap/update-message/${messageId}`, body)
        .then(async res => {
          await this.$store.dispatch('whatsapp/getUpdateListMessage')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: toastSuccess,
              variant: 'success',
            },
          }, 500)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: toastFailed,
              variant: 'danger',
            },
          }, 500)
        })
    },
    handleChangeTab(newTab = 'template') {
      if (newTab === 'template') {
        this.isEditMode = false
      } else {
        this.isEditMode = true
      }
      if (this.isPickupMode) {
        this.previewMessage = this.isEditMode ? this.customTemplateEditPickup : this.komshipPickupTemplate
      } else {
        this.previewMessage = this.isEditMode ? this.customTemplateEditCOD : this.komshipCODTemplate
      }
    },
    onEditorReady(quill) {
      this.quill = quill
    },
    generateHTMLContent() {
      let html = ''

      const transformStyle = { strike: 'strike', bold: 'b', italic: 'i' }

      if (this.quill) {
        const { ops } = this.quill.editor.delta
        ops.forEach(quillNode => {
          let content = quillNode.insert.replaceAll(/\n/g, '<br>').replace(/\u00a0/g, ' ')

          if (quillNode.attributes) {
            Object.entries(transformStyle).forEach(([styleName, tagName]) => {
              if (quillNode.attributes[styleName]) {
                content = this.convertToTag(content, tagName)
              }
            })
          }

          html += content
        })
      }

      return html
    },
    convertToTag(content = '', tagName = '') {
      let result = content

      if (result.startsWith(' ')) {
        const trimmedContent = result.trimLeft()
        const emptySpace = result.replace(trimmedContent, '')

        result = `${emptySpace}<${tagName}>${trimmedContent}</${tagName}>`
      } else if (result.endsWith(' ')) {
        const trimmedContent = result.trimRight()
        const emptySpace = result.replace(trimmedContent, '')

        result = `<${tagName}>${trimmedContent}</${tagName}>${emptySpace}`
      } else {
        result = `<${tagName}>${result}</${tagName}>`
      }

      return result
    },
    setCustomerPhone(newval) {
      this.customerPhone = newval.customerPhone
    },
  },
}
